import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ContactusComponent } from './contactus/contactus.component';
import { KaribuComponent } from './karibu/karibu.component';
import { MwanzoComponent } from './mwanzo/mwanzo.component';
import { KishaComponent } from './kisha/kisha.component';
import { MwishoComponent } from './mwisho/mwisho.component';
import { EnheeComponent } from './enhee/enhee.component';
import { AlafuComponent } from './alafu/alafu.component';
import { BaadayeComponent } from './baadaye/baadaye.component';

const routes: Routes = [
{path:'',component:MwanzoComponent},
{path:'mwanzo',component:MwanzoComponent},
{path:'kisha',component:KishaComponent},
{path:'enhee',component:EnheeComponent},
{path:'alafu',component:AlafuComponent},
{path:'baadaye',component:BaadayeComponent},
{path:'mwisho',component:MwishoComponent},
{path:'karibu',component:KaribuComponent},
{path:'contactus',component:ContactusComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
