import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ContactusComponent } from './contactus/contactus.component';
import { KaribuComponent } from './karibu/karibu.component';
import { MwanzoComponent } from './mwanzo/mwanzo.component';
import { KishaComponent } from './kisha/kisha.component';
import { MwishoComponent } from './mwisho/mwisho.component';
import { EnheeComponent } from './enhee/enhee.component';
import { AlafuComponent } from './alafu/alafu.component';
import { BaadayeComponent } from './baadaye/baadaye.component';

@NgModule({
  declarations: [
    AppComponent,
    ContactusComponent,
    KaribuComponent,
    MwanzoComponent,
    KishaComponent,
    MwishoComponent,
    EnheeComponent,
    AlafuComponent,
    BaadayeComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
