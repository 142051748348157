import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import { MailService } from '../services/mail.service';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.css']
})
export class ContactusComponent implements OnInit {

  constructor(private barua: MailService) { }
  sent;
  sending;
  notsent;
  message;
  notsenterror;
  errormessage;
  showsend;
  email;phonenumber;apptype;
  ngOnInit() {
  	this.sent=false;
  	this.notsent=false;
    this.sending=false;
    this.showsend=false;
    this.notsenterror=false;
  }
  check(){
    if(this.email!=null && this.phonenumber!=null && this.apptype!=null){
      this.showsend=true;
    }
    else{
      this.showsend=false;
    }
  }
  mail(){
      this.notsenterror=true;
      if(this.email==null){
          this.errormessage='Please provide your email address.';
      }
      if(this.phonenumber==null){
          this.errormessage='Please provide your phonenumber.';
      }
      if(this.apptype=='undefined' || this.apptype==null){
          this.errormessage='Please select the app you\'d like developed.';
      }
  		if(this.email!=null && this.phonenumber!=null &&  this.apptype!=null){
      this.notsenterror=false;
      this.sending=true;
        this.barua.mail(this.email,this.phonenumber,this.apptype).subscribe(data=>{
        this.message=data;
        if(this.message=="Email sent successfully. We'll contact you."){
          this.sent=true;
          this.notsent=false;
          this.sending=false;
        }
        else{
          this.sent=false;
          this.notsent=true;
          this.sending=false;
        }
      });
      }
  }
}
