import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { HttpHeaders } from "@angular/common/http";
import { HttpParams } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class MailService {

	api='./mail.php';
	//api='http://localhost/new-mfanyakazi-site/backend/mail.php';
	constructor(private http:HttpClient) { }
	mail(email,phonenumber,apptype){
		let formDetails={'email':email,'phonenumber':phonenumber,'apptype':apptype};
		return this.http.post(this.api,formDetails);
	}
}
